import { Dispatch } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Vip, Employee } from './types';
import { userActions } from '../redux/slices/user-slice';
import { RootState } from '../redux/ConfigureStore';
const { REACT_APP_VOUCHER_EP_API_URL, REACT_APP_VOUCHER_EP_API_KEY } =
  process.env;

export const addUser =
  (userData: Vip | Employee) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(userActions.addUserBegin());
    if (userData.type === 'employee') {
      try {
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': REACT_APP_VOUCHER_EP_API_KEY as string,
          Authorization: 'Bearer ' + getState().auth.token,
        };
        const userVoucherUrl = '/usersvouchers';
        const res = await axios.post(
          REACT_APP_VOUCHER_EP_API_URL + userVoucherUrl,
          userData,
          { headers },
        );
        dispatch(userActions.addUserSuccess(res.data));
      } catch (err: any) {
        if (err instanceof AxiosError && err.response) {
          if ((err.response as AxiosResponse).status === 429) {
            dispatch(
              userActions.addUserError(
                'We are sorry but we cannot proceed with your request. Please try again later. Thank you.',
              ),
            );

            return;
          }
        }
        dispatch(
          userActions.addUserError(
            err.response?.data?.message ?? 'Your request failed',
          ),
        );
      }
    } else {
      try {
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': REACT_APP_VOUCHER_EP_API_KEY as string,
        };
        const userVoucherUrl = '/vipvouchers';
        const res = await axios.post(
          REACT_APP_VOUCHER_EP_API_URL + userVoucherUrl,
          userData,
          { headers },
        );
        dispatch(userActions.addUserSuccess(res.data));
      } catch (err: any) {
        dispatch(
          userActions.addUserError(
            err.response?.data?.message ?? 'Your request failed',
          ),
        );
      }
    }
  };
