import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../redux/slices/user-slice';
import { MdHome } from 'react-icons/md';
import '../../css/ErrorPage.css';
import '../../css/RequestSummay.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';

export const ErrorPage = () => {
  const error = useSelector<any, string>((state) => state.user.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeChange = () => {
    navigate('/');
    dispatch(userActions.addUserUpload());
  };

  return (
    <div>
      <h2 className="errorTitle">Your request ended in Error.</h2>
      <p className="errorText">
        <span className="bold">Error message:</span> {error}
      </p>
      <p className="errorText">
        Please contact Customer Support at{' '}
        <a href="mailto:employee_appaccess@discoveryplus.com" className="bold">
          employee_appaccess@discoveryplus.com
        </a>
        .
      </p>
      <div className="errorButtonWrapper">
        <CustomButton
          onClick={routeChange}
          text="< Back"
          MobileIcon={MdHome}
          extraClasses="errorButtonBack"
        />
      </div>
    </div>
  );
};
