export function emailValidation(
  email: string,
  whitelistedDomains: string[],
): string | undefined {
  const errorMessage = 'Your email adress is invalid';
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  const emailParts = email.split('@');
  if (emailParts.length !== 2) {
    return errorMessage;
  }

  const account = emailParts[0];
  const address = emailParts[1];
  if (account.length > 64) {
    return errorMessage;
  }
  if (address.length > 255) {
    return errorMessage;
  }

  const domainParts = address.split('.');
  if (!whitelistedDomains.includes(address.toLowerCase())) {
    return 'You are not eligible to receive a voucher.';
  }
  if (domainParts[1].length > 3) {
    return errorMessage;
  }

  if (!regex.test(email)) {
    return errorMessage;
  }
}
